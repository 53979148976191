<template>
  <main
    class="blank-aside-js page_bg_grey"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <p class="main-title weight-700">
      {{ $t('MANAGEMENT.PREPAID_PROFIT_PERCENTAGE') }}
    </p>

    <section class="filter-part sm-content">
      <div class="form-item">
        <label>{{ $t('MANAGE_SHANIV.CATEGORY') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('SUPPLIER_PAYMENT.CARD_TYPE') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('REPORT_SALES.SUPPLIER_ID') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('GIFTCARD_PAYMENT_RESULT.PRODUCT') }}</label>
        <div class="m-t-5 m-b-15 icon-input">
          <input
            type="text"
            class="input"
            :placeholder="$t('REPORT_SALES.EVERYTHING')"
          />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>

    <section class="sm-content m-t-10">
      <label>{{ $t('DESTINATION.TAGS') }}</label>
      <div class="tag-block">
        <img src="/assets/img/icons/search-light.svg" alt="" class="search-icon" />
        <div class="tag-close_btn total-tag-close">
          <img src="/assets/img/icons/times-light.svg" alt="" />
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('DESTINATION.TAGS') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
        <div class="tag-item tag-item-active">
          {{ $t('DESTINATION.TAGS') }}
          <div class="tag-close_btn">
            <img src="/assets/img/icons/times-light.svg" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="sm-content m-t-30">
      <div class="d-flex align-items-end bottom-setting">
        <div class="detail-form">
          <label>{{ $t('MANAGEMENT.CUSTOMER_TYPE') }}</label>
          <div class="m-t-5">
            <input
              type="text"
              class="input"
              :placeholder="$t('REPORT_SALES.EVERYTHING')"
            />
          </div>
        </div>
        <vue-slider 
          v-model="sliderValue"
          :direction="sliderDirection"
          class="cus-vue-slider"
          tooltip="always"
          :min="0"
          :max="100"
        ></vue-slider>
      </div>
      <div class="d-flex align-items-end bottom-setting m-t-30">
        <div class="detail-form m-b-15">
          <label>{{ $t('MANAGEMENT.PROFIT_PRO') }}</label>
          <div class="m-t-5">
            <input
              type="number"
              class="input"
              value="10"
            />
          </div>
        </div>
        <button class="primary-btn m-b-15">
          {{ $t('MANAGEMENT.APPLY_PROFIT_TO_RESELLERS') }}
        </button>
        <button class="primary-btn bg-red3 m-b-15">
          {{ $t('MANAGEMENT.REMOVE_PROFIT_ON_DISTRIBUTORS') }}
        </button>
      </div>
      <div class="d-flex align-items-end bottom-setting m-t-15">
        <div class="detail-form m-b-15">
          <label>{{ $t('MANAGEMENT.POINTS') }}</label>
          <div class="m-t-5">
            <input
              type="number"
              class="input"
              value="2"
            />
          </div>
        </div>
        <button class="primary-btn m-b-15">
          {{ $t('MANAGEMENT.APPLY_GIFT_TO_MARKETERS') }}
        </button>
        <button class="primary-btn bg-red3 m-b-15">
          {{ $t('MANAGEMENT.REMOVE_GIFT_ON_DISTRIBUTORS') }}
        </button>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'SCPercentage',
  data() {
    return {
      sliderValue: [0, 50]      
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-part {
  display: flex;
  justify-content: space-between;
  .form-item {
    width: calc(25% - 30px);
  }
}
.detail-form {
  width: 100%;
  max-width: 250px;
}
.bottom-setting {
  .primary-btn {
    width: unset;
    padding: 0 20px;
    margin-right: 30px;
    min-width: 350px;
    font-size: 16px;
  }
}
.cus-vue-slider {
  width: 100% !important;
  margin-right: 30px;
}

@media screen and (max-width: 980px) {
  .filter-part {
    flex-wrap: wrap;
    .form-item {
      width: 100%;
    }
  }
  .cus-vue-slider {
    margin-top: 50px;
    margin-right: 0;
  }
  .bottom-setting {
    flex-wrap: wrap;
    .primary-btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .detail-form {
    max-width: unset;
  }
}

.ltr-type {
  .cus-vue-slider {
    margin-right: 0;
    margin-left: 30px;
  }
  .bottom-setting .primary-btn {
    margin-right: 0;
    margin-left: 30px;
  }
}
</style>